import React, { useState, useEffect, MouseEvent, ChangeEvent } from 'react'
import axios from 'axios'
import { Link } from 'gatsby'
import { differenceInYears } from 'date-fns'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import { useLocation } from '@reach/router'
import { translate, InjectedTranslateProps } from 'react-i18next'
import { sendDataLayerEvent, getParameterByName, sendCDPFormData } from 'src/shared/helpers'

import MSG from './msgAccount.json'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import AcceptTerms from 'src/components/AcceptTerms/OpenAccount'

import errorImg from 'src/assets/images/shared/erro-pig.png'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import BaixeOApp from 'src/assets/images/qrcode-header.jpg'
import OpenCommunity from 'src/assets/images/shared/OpenCommunityQrCode.png'
import sucesso from '../images/sucesso.png'

import { Container, CloseButton } from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useDataLayerHeader from 'src/hooks/useDataLayer/dataLayerHeader'

interface IOpenAccountRightFormProps extends InjectedTranslateProps {
  closeModal: () => void;
  customOrigin?: string;
  pageMei?: boolean;
  qrBaixeApp?: HTMLImageElement | string;
  urlContaKids?: string;
  asFilledLink?: string;
  agoraBaixeOAppLink?: string;
  qrCodeProduct?: string;
  qrCodeContaKids?: string;
  formName?: string;
  deepLink?: string;
  typeDataLayer?: string;
  dataLayer: IDataLayerParams;
  downloadAppCustomMessage?: string;
  formGlobalParceiros: boolean;
}
interface IFormValues {
  nome: string;
  email: string;
  celular: string;
  cpf: string;
  dataNascimento: string;
}

function OpenAccountRightForm ({
  closeModal,
  customOrigin,
  pageMei,
  t,
  qrBaixeApp,
  urlContaKids,
  asFilledLink,
  qrCodeProduct,
  qrCodeContaKids,
  formName,
  deepLink,
  typeDataLayer,
  dataLayer,
  agoraBaixeOAppLink,
  downloadAppCustomMessage,
  formGlobalParceiros,
}: IOpenAccountRightFormProps) {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const location = useLocation()
  const windowWidth = useWidth(200)
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ hasAccount, setHasAccount ] = useState(false)
  const [ underAge, setUnderAge ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ filled, setFilled ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ message, setMessage ] = useState(MSG.PF)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)

  const [ sendDatalayerEvent ] = useDataLayer()
  const [ sendDatalayerEventHeader ] = useDataLayerHeader()

  const SuccessMessageMEI = location.pathname.includes('/empresas/conta-digital/mei/')
  const publicOfferings = location.pathname.includes('/pra-voce/investimentos/ofertas-publicas')
  const InvestmentCommunity = location.pathname.includes('/pra-voce/investimentos/comunidade-de-investimentos')

  const urlMobile = urlContaKids ? urlContaKids : 'https://inter-co.onelink.me/Qyu7/iegctop0'
  const urlDesktop = '/pra-voce/conta-digital/conta-kids/'
  const urlUnderAge = windowWidth < WIDTH_MD ? urlMobile : urlDesktop

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))

    if (SuccessMessageMEI) {
      setMessage(MSG.MEI)
    } if (publicOfferings) {
      setMessage(MSG.offers)
    }
  }, [ message ])

  const returnFormattedDate = (date: Date) => {
    const formatedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
    return formatedDate
  }

  const verifyIfDOBAreValid = (dob: string): boolean => {
    let isValid: boolean = false
    const formatedDate = dob.replace(/\D/g, ',').split(',').reverse().join('-')

    const timeElapsed = Date.now()
    const today = new Date(timeElapsed)

    const todayFormattedDate = returnFormattedDate(today)

    const dobTimestamp = new Date(formatedDate).getTime()
    const todayTimestamp = new Date(todayFormattedDate).getTime()

    if (!isNaN(dobTimestamp) && dobTimestamp < todayTimestamp) {
      isValid = true
    } else {
      isValid = false
    }

    return isValid
  }

  const sendForm = async (data: IFormValues) => {
    setLoading(true)
    const newDate = data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join('-')

    const result = differenceInYears(new Date(), new Date(newDate))

    const formData = {
      ...data,
      cpf: data.cpf.replace(/\D/g, ''),
      celular: data.celular.replace(/\D/g, ''),
      dataNascimento: data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join('-'),
      termoAceito: accept,
      origin: customOrigin || 'site-institucional',
      utmCampaign: utmCampaign?.toString(),
      utmMedium: utmMedium?.toString(),
      utmSource: utmSource?.toString(),
      utmContent: utmContent?.toString(),
    }

    try {
      if (result < 18) {
        setUnderAge(true)
        typeDataLayer === 'ga_event_header'
          ? sendDatalayerEventHeader({
              section: 'null',
              sub_section: 'null',
              element_action: 'click button',
              element_name: t('continue'),
              c_page: window.location.href,
          })
          : sendDatalayerEvent({
              ...dataLayer,
              section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
              section_name: dataLayer ? dataLayer.section_name : '',
              element_name: t('continue'),
              element_action: 'click button',
              element_previous: dataLayer.element_previous === 'null' ? 'null' : `${dataLayer.element_name}, ${dataLayer.section}`,
          })
      } else {
        await axios.put(`${URLS.OPEN_ACCOUNT_V2}`, formData)
        setLoading(false)
        setSent(true)
        sendDataLayerEvent('form_submitted')
        typeDataLayer === 'ga_event_header'
          ? sendDatalayerEventHeader({
              section: 'null',
              sub_section: 'null',
              element_action: 'submit',
              element_name: t('continue'),
              c_page: window.location.href,
              step: 'success',
          })
          : sendDatalayerEvent({
              ...dataLayer,
              section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
              section_name: dataLayer ? dataLayer.section_name : '',
              element_name: t('continue'),
              element_action: 'submit',
              element_previous: dataLayer.element_previous === 'null' ? t('isHaveAnAccount') : `${dataLayer.element_name}, ${dataLayer.section}`,
              step: 'success',
          })
        sendCDPFormData({ formName: formName, cpf: formData.cpf, email: formData.email })
      }
    } catch (e) {
      setError(true)
      setLoading(false)
      setFilled(false)
      typeDataLayer === 'ga_event_header'
        ? sendDatalayerEventHeader({
            section: 'null',
            sub_section: 'null',
            element_action: 'click button',
            element_name: t('continue'),
            c_page: window.location.href,
        })
        : sendDatalayerEvent({
            ...dataLayer,
            section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
            section_name: dataLayer ? dataLayer.section_name : '',
            element_name: t('continue'),
            element_action: 'click button',
            element_previous: dataLayer.element_previous === 'null' ? t('isHaveAnAccount') : `${dataLayer.element_name}, ${dataLayer.section}`,
      })
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
    setFilled(false)
  }

  const asFilled = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    if (windowWidth < WIDTH_MD) {
      window.location.href = agoraBaixeOAppLink ? agoraBaixeOAppLink : (asFilledLink || 'https://br6du.app.goo.gl/enBT')
    } else {
      setSent(false)
      setFilled(true)
    }
  }

  const asUnderAge = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    window.location.href = urlUnderAge
  }

  const handleHasAccount = () => {
    setHasAccount(!hasAccount)
    typeDataLayer === 'ga_event_header'
      ? sendDatalayerEventHeader({
          section: 'null',
          sub_section: 'null',
          element_action: 'click button',
          element_name: 'Já sou cliente',
          c_page: window.location.href,
      })
      : sendDatalayerEvent({
          section: 'dobra_03',
          element_action: 'click button',
          element_name: 'Já sou cliente',
          section_name: 'Abra agora sua Conta Digital e crie a sua comunidade.',
      })
  }

  const closeButton = closeModal && (
    <CloseButton className='border-0 bg-transparent pt-3 pt-md-4' onClick={closeModal}>
      <Close width='24' height='24' color={` ${pageMei ? 'grayscale--500' : 'orange--extra'}`} />
    </CloseButton>
  )

  return (
    <>
      { error && (
        <Container isModal={closeModal} className='d-flex align-items-center'>
          {closeButton}
          <div className='col-12 text-center'>
            <h3 className='fs-20 lh-24 mb-5'>{t('errorTitle')}</h3>
            <img src={errorImg} alt='Imagem de erro' />
            <p className='text-grayscale--400 fs-18 lh-22 pt-3'>
              {t('errorMessage')} <br />
              <button
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  handleReturn(e)
                  typeDataLayer === 'ga_event_header'
                    ? sendDatalayerEventHeader({
                        section: 'null',
                        sub_section: 'null',
                        element_action: 'click button',
                        element_name: t('errorLinkMessage'),
                        c_page: window.location.href,
                    })
                    : sendDatalayerEvent({
                        ...dataLayer,
                        section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
                        element_name: t('errorLinkMessage'),
                        element_previous: dataLayer ? dataLayer.element_name + ' ' + dataLayer.section : t('isHaveAnAccount'),
                    })
                }} title={t('errorLinkMessage') + t('errorFinalMessage')}
                className='bg-transparent border-0 fw-600'
              >
                {t('errorLinkMessage')}
              </button>
              {t('errorFinalMessage')}
            </p>
          </div>
        </Container>
      )}
      { sent && (
        <Container isModal={closeModal} className='d-flex align-items-center sent'>
          {closeButton}
          <div className='col-12 text-center'>
            <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--400 fw-600'>
              {t('receivedYourData')}
            </p>
            <h3 className='fs-24 lh-28 text-grayscale--500 mb-4 fw-600'>
              <span className='d-block'>{t('now')} <span className='text-orange--extra'>{t('downloadOurApp')}</span></span>
              {t('andOpenYourAccount')}
              <span className='d-block'>{publicOfferings ? ' e invista nas Ofertas Públicas.' : ''}</span>
            </h3>
            <div className='col-12 pb-2 mx-auto d-none d-md-block 1'>
              {
                qrCodeProduct ? <img src={qrCodeProduct} alt='Baixe o App Inter' /> : <img src={qrBaixeApp || BaixeOApp} alt='Baixe o App Inter' />
              }
            </div>
            <div className='col-12 d-none d-md-block'>
              <hr />
              <p className='fs-14 lh-17 text-left' dangerouslySetInnerHTML={{ __html: t('sentMessage') }} />
            </div>
          </div>
        </Container>
      )}
      { hasAccount && (
        <Container isModal={closeModal} className='d-flex align-items-center sent'>
          {closeButton}
          <div className='col-12 text-center'>
            <h3 className='fs-24 lh-28 text-grayscale--500 mb-4 fw-600'>
              <span className='d-block'>Acesse as <span className='text-orange--extra'>comunidades de investimentos no app</span> e </span> crie ou entre em alguma.
            </h3>
            <div className='col-12 pb-2 mx-auto d-none d-md-block'>
              <img src={OpenCommunity} alt='Entrar em comunidades' />
            </div>
            <div className='col-12 d-none d-md-block'>
              <hr />
              <p className='fs-14 lh-17 text-left'>
                {t('sentMessage')}
              </p>
            </div>
          </div>
        </Container>
      )}
      { underAge && (
        <Container isModal={closeModal} className='d-flex align-items-center under-age'>
          {closeButton}
          <div className='col-12 text-md-center text-lg-left'>
            <h3 className='fs-16 lh-20 text-grayscale--400 fw-400 fw-md-600'>
              {t('underAge')}
            </h3>
            <p className='fs-20 lh-23 fs-md-24 lh-md-28 text-grayscale--500 mb-4 fw-600'>
              <span className='d-block'>
                {t('knowOur')}
                <span
                  className='text-orange--extra cursor-pointer'
                  onClick={(evt: MouseEvent<HTMLButtonElement>) => {
                    asUnderAge(evt)
                    typeDataLayer === 'ga_event_header'
                      ? sendDatalayerEventHeader({
                          section: 'null',
                          sub_section: 'null',
                          element_action: 'click button',
                          element_name: t('kidsAccount'),
                          c_page: window.location.href,
                          redirect_url: urlUnderAge,
                      })
                      : sendDatalayerEvent({
                          ...dataLayer,
                          section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
                          element_name: t('kidsAccount'),
                          element_previous: dataLayer ? dataLayer.element_name + ' ' + dataLayer.section : t('isHaveAnAccount'),
                          redirect_url: urlUnderAge,
                      })
                  }}
                > {t('kidsAccount')}
                </span>,
              </span>
              {t('alsoCompleteDigitalFree')}
            </p>
            <div className='col-12 pb-2 mx-auto d-lg-none text-center'>
              <img src={sucesso} alt='Sucesso' />
            </div>
            <div className='col-12 pb-2 mx-auto d-none d-lg-block text-center'>
              <img src={qrCodeContaKids ? qrCodeContaKids : BaixeOApp} alt='Baixe o App Inter' />
            </div>
            <div className='col-12 d-none d-lg-block px-0'>
              <hr />
              <p className='fs-14 lh-17 text-left'>
                {t('sentMessage')}
              </p>
              <p className='fs-14 lh-17 text-left'>
                {t('underPrivacy')}
              </p>
            </div>
          </div>
        </Container>
      )}
      {filled && (
        <Container isModal={closeModal} className='d-flex align-items-center filled'>
          {closeButton}
          <div className='col-12 text-center'>
            <h3 className='fs-20 lh-24 fw-600 text-grayscale--500' dangerouslySetInnerHTML={{ __html: t('nowDownload') }} />
            <p className='fs-16 lh-20 text-grayscale--400 mb-5' dangerouslySetInnerHTML={{ __html: t('pointPhone') }} />
            <div className='col-12'>
              <img src={qrBaixeApp ? qrBaixeApp : BaixeOApp} alt='Baixe o App Inter' />
            </div>
          </div>
        </Container>
      )}
      <Container isModal={closeModal} className={`${error || sent || filled || underAge || hasAccount ? 'd-none' : 'd-flex'} align-items-center`}>
        {closeButton}
        <div className='row'>
          {
            pageMei ? (
              <div className='text-center mt-3 mb-2'>
                <h2 className='fs-20 lh-24 text-left text-center mb-2 mt-5 mt-md-3 text-grayscale--500'>
                  Abra agora sua Conta Digital
                </h2>
                <p className='fs-14 lh-20 text-grayscale--400 mb-0'>
                  Para abrir uma conta digital MEI você precisa de uma conta digital PF. Ela é digital, completa e gratuita.
                </p>
              </div>
            ) : (
              <div className='col-12 text-center py-4 pt-lg-0'>
                <h2 className={`fs-20 lh-24 mb-2 text-grayscale--500 ${formGlobalParceiros ? 'font-citrina text-left mt-2 pl-3' : 'mt-4 mt-md-3 text-left text-md-center'}`}>
                  {formGlobalParceiros ? 'Faça seu cadastro' : t('openYourAccount')}
                  <span className='d-block'>{publicOfferings && ' e invista nas Ofertas Públicas.'}</span>
                  <span className='d-block'>{InvestmentCommunity && ' e crie a sua comunidade.'}</span>
                </h2>
              </div>
            )
          }
          <div className='col-12'>
            <form className='form--default' name='open_account' onSubmit={handleSubmit(sendForm)}>
              <div className='body-form'>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='nome' className='fs-12 lh-15 fw-600'>{t('inputName.label')}</label>
                  <input
                    ref={register({
                      required: t('inputName.error'),
                      validate: {
                        isName: (value: string) => Validations.name(value) || t('inputName.error'),
                      },
                    })}
                    name='nome'
                    id='nome'
                    type='text'
                    maxLength={100}
                    placeholder={t('inputName.placeholder')}
                  />
                  {errors.nome && <p className='fs-12 text-red--base mb-0 text-right'>{errors.nome.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='celular' className='fs-12 lh-15 fw-600'>{t('inputPhone.label')}</label>
                  <input
                    ref={register({
                      required: t('inputPhone.error'),
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: t('inputPhone.error'),
                      },
                    })}
                    name='celular'
                    id='celular'
                    type='tel'
                    placeholder={t('inputPhone.placeholder')}
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('celular', Masks.MaskPHONE(event.currentTarget.value))}
                  />
                  {errors.celular && <p className='fs-12 text-red--base mb-0 text-right'>{errors.celular.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='email' className='fs-12 lh-15 fw-600'>{t('inputEmail.label')}</label>
                  <input
                    ref={register({
                      required: t('inputEmail.error'),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t('inputEmail.error'),
                      },
                    })}
                    name='email'
                    id='email'
                    type='text'
                    placeholder={t('inputEmail.placeholder')}
                  />
                  {errors.email && <p className='fs-12 text-red--base mb-0 text-right'>{errors.email.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='cpf' className='fs-12 lh-15 fw-600'>{t('inputCPF.label')}</label>
                  <input
                    ref={register({
                      required: t('inputCPF.error'),
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || t('inputCPF.error'),
                      },
                    })}
                    name='cpf'
                    id='cpf'
                    type='tel'
                    placeholder={t('inputCPF.placeholder')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                  />
                  {errors.cpf && <p className='fs-12 text-red--base mb-0 text-right'>{errors.cpf.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 pb-1'>
                  <label htmlFor='dataNascimento' className='fs-12 lh-15 fw-600'>{t('inputBirthDate.label')}</label>
                  <input
                    ref={register({
                      required: t('inputBirthDate.error'),
                      validate: {
                        isDate: (value: string) => (Validations.dateValid(value) && verifyIfDOBAreValid(value)) || t('inputBirthDate.error'),
                      },
                    })}
                    name='dataNascimento'
                    id='dataNascimento'
                    type='tel'
                    placeholder={t('inputBirthDate.placeholder')}
                    maxLength={10}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('dataNascimento', Masks.MaskDATE(event.currentTarget.value))}
                  />
                  {errors.dataNascimento && <p className='fs-12 text-red--base mb-0 text-right'>{errors.dataNascimento.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3'>
                  <AcceptTerms
                    accept={accept} setAccept={setAccept}
                    name='conta-digital-pf'
                    dataLayer={dataLayer}
                    typeDataLayer={typeDataLayer}
                  />
                </div>
                {InvestmentCommunity ? (
                  <div className='col-12 text-center'>
                    <div className='row'>
                      <div className='col-6'>
                        <button
                          type='button' onClick={handleHasAccount}
                          className='btn btn-white border border-orange--extra rounded-2 fs-14 fw-600 mx-auto text-orange--extra text-none'
                        >
                          Já sou cliente
                        </button>
                      </div>
                      <div className='col-6'>
                        <button
                          type='submit'
                          title={t('continue')}
                          disabled={!accept || loading}
                          className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                        >
                          {loading ? t('sending') : t('continue')}
                        </button>
                      </div>
                    </div>
                  </div>
                  ) : (
                    <div className='col-12 text-center'>
                      {deepLink ? (
                        <div className='d-block d-md-none'>
                          <button
                            type='submit'
                            title={t('continue')}
                            disabled={!accept || loading}
                            className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                            onClick={() => {
                              window.location.href = deepLink
                            }}
                          >
                            {loading ? t('sending') : t('continue')}
                          </button>
                        </div>
                      ) : (
                        <div className='d-block d-md-none'>
                          <button
                            type='submit'
                            title={t('continue')}
                            disabled={!accept || loading}
                            className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                          >
                            {loading ? t('sending') : t('continue')}
                          </button>
                        </div>
                      )}
                      <div className='d-none d-md-block'>
                        <button
                          type='submit'
                          title={t('continue')}
                          disabled={!accept || loading}
                          className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                        >
                          {formGlobalParceiros
                          ? loading ? 'Enviando...' : 'Cadastrar'
                          : loading ? t('sending') : t('continue')
                          }
                        </button>
                      </div>
                    </div>
                  )
                }
              </div>
            </form>
            <div className='text-center pt-3'>
              {!formGlobalParceiros &&
                <span className='fs-14 lh-18 text-grayscale--400'>
                  {downloadAppCustomMessage ? '' : t('alreadyRegistered')}
                  <button
                    onClick={(evt: MouseEvent<HTMLButtonElement>) => {
                      asFilled(evt)
                      typeDataLayer === 'ga_event_header'
                        ? sendDatalayerEventHeader({
                            section: 'null',
                            sub_section: 'null',
                            element_action: 'click button',
                            element_name: t('downloadApp'),
                            c_page: window.location.href,
                            step: 'success',
                        })
                        : sendDatalayerEvent({
                            ...dataLayer,
                            section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
                            section_name: dataLayer ? dataLayer.section_name : '',
                            element_name: t('downloadApp'),
                            element_previous: dataLayer ? dataLayer.element_name + ' ' + dataLayer.section : t('isHaveAnAccount'),
                            step: 'success',
                            redirect_url: windowWidth < WIDTH_MD ? urlMobile : null,
                        })
                    }
                  }
                    title={t('downloadAppNow')}
                    className='text-orange--extra fw-600 bg-transparent border-0'
                  >{downloadAppCustomMessage ? downloadAppCustomMessage : t('downloadApp')}
                  </button>
                </span>
              }
              {
                InvestmentCommunity && (
                  <Link
                    to='/pra-voce/investimentos/'
                    className='fs-16 fw-600 lh-20 text-orange--extra pt-4 d-block'
                    onClick={() => {
                      typeDataLayer === 'ga_event_header'
                        ? sendDatalayerEventHeader({
                            section: 'dobra_03',
                            sub_section: 'null',
                            element_action: 'click button',
                            element_name: 'Saiba mais sobre Investimentos',
                            c_page: window.location.href,
                        })
                        : sendDatalayerEvent({
                            section: 'dobra_03',
                            element_action: 'click button',
                            element_name: 'Saiba mais sobre Investimentos',
                            section_name: 'Abra agora sua Conta Digital e crie a sua comunidade',
                        })
                    }}
                  > Saiba mais sobre Investimentos
                  </Link>
                )
              }
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default translate('OpenAccountRightForm')(OpenAccountRightForm)
